<main>
    <app-banner></app-banner>
    <app-about></app-about>
    <app-jobs></app-jobs>
    <app-proyects></app-proyects>
    <app-more-proyects></app-more-proyects>
    <app-interests></app-interests>
    <app-contact></app-contact>
</main>

