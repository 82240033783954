import { Component, OnInit, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations:[
    trigger("animateFooter", [
      transition(":enter", [
        query("*", [
          style({opacity: 0, transform: "translateY(100%)"}),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity:1, transform: "none"})
            )
          ])
        ])
      ])
    ])
  ]
})
export class FooterComponent implements OnInit {
  isRotated: boolean = false;
  rotationInterval: any;
  constructor(
    public analyticsService: AnalyticsService,private renderer: Renderer2, private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.startRotation();
  }
  startRotation() {
    this.rotationInterval = setInterval(() => {
      this.toggleRotation();
    }, 5000); // Adjust interval as needed
  }

  toggleRotation() {
    this.isRotated = !this.isRotated;
    const rotation = this.isRotated ? 'rotateY(180deg)' : 'rotateY(0deg)';
    this.renderer.setStyle(this.el.nativeElement.querySelector('#rotating-logo'), 'transform', rotation);
  }

  ngOnDestroy() {
    clearInterval(this.rotationInterval);
  }
}
