<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-xl-block">
            <li class="linkedIn-me">
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "github")' class="navbar-brand text-light" href="https://www.linkedin.com/in/joze-august-480b12128/" target='_blank'>
                    <img src='assets/images/logo6.png' width='100%' id="rotating-logo" [ngClass]="{'rotated': isRotated}" ngbTooltip="Click on logo to go to Linkedin profile"/>
                </a>
            </li>
            
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' href="mailto:joze.jayde.august@gmail.com" target='_blank'>joze.jayde.august@gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a>
            <div>Modified by Joze August</div><br>
        </a>
        <a>
            <div>Built with Angular by Jose Andres</div>
        </a>
        <a>
            <div class="mt-2">Designed by Brittany Chiang</div>
        </a>
    </div>

</footer>