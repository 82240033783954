import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

declare var grecaptcha: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  captchaVerified: boolean = false;
  //modalService:any;
  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    // Load reCAPTCHA script dynamically
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // reCAPTCHA script loaded, initialize reCAPTCHA
      grecaptcha.render('captcha', {
        'sitekey': '6LcReaUpAAAAABNghBTRuVJsUqkBj9AmdPzCIyAq',
        'callback': this.handleCaptchaVerification.bind(this),
        'expired-callback': this.handleCaptchaExpiration.bind(this) // Handle expiration
 
      });
    };
    document.head.appendChild(script);
  }

  handleCaptchaVerification() {
    // Set captchaVerified to true when reCAPTCHA is successfully verified
    this.captchaVerified = true;
  }
  handleCaptchaExpiration() {
    // Reset the state when reCAPTCHA expires
    this.captchaVerified = false;
  }

  sendEmail(){
    window.location.href = 'mailto:joze.jayde.august@gmail.com';//target="_blank"'
  }
 /* openModal(): void {
    this.modalService.openModal();
  }*/
}
